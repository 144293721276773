import {Container, ContainerProps} from '@mui/material';
import {ReactNode, useState} from 'react';

type FlipperProps = {
	children: [ReactNode, ReactNode];
}

export default function Flipper({children}: FlipperProps) {
	const [front, back]   = children;
	const [flip, setFlip] = useState(false);
	
	const extraSx: ContainerProps['sx'] = {
		position: 'relative',
		
		'> div': {
			transition: 'transform 1s',
			backfaceVisibility: 'hidden',
			
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			
			'&:nth-of-type(1)': {
				transform: 'rotateY(0)',
				
				'&[data-flip=flip]': {
					transform: 'rotateY(180deg)'
				}
			},
			
			'&:nth-of-type(2)': {
				transform: 'rotateY(180deg)',
				
				'&[data-flip=flip]': {
					transform: 'rotateY(0)'
				}
			}
		}
	};
	
	const props = {
		role: 'button',
		onClick: () => setFlip((cur) => !cur),
		sx: {
			...extraSx
		}
	};
	
	return <Container {...props}>
		<div data-flip={flip ? 'flip' : ''}>{front}</div>
		<div data-flip={flip ? 'flip' : ''}>{back}</div>
	</Container>;
}