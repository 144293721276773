import {createTheme} from '@mui/material';
import {blueGrey, deepPurple, pink} from '@mui/material/colors';

export const useTheme = () => createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 320,
			md: 640,
			lg: 1200,
			xl: 1536
		}
	},
	palette: {
		background: {
			default: blueGrey[100]
		},
		primary: deepPurple,
		secondary: pink
	},
	components: {
		MuiCardHeader: {
			styleOverrides: {
				avatar: {
					marginRight: 28
				}
			}
		}
	}
});