import {Box, Card as MuiCard, CardContent, Grid, Typography} from '@mui/material';
import React, {forwardRef, PropsWithChildren, ReactNode} from 'react';
import CalendarBackground from './CalendarBackground';
import CardHeader from './CardHeader';

const useFontSize = () => {
	return {
		xs: '4em',
		sm: '3em',
		md: '3em',
		lg: '2em',
		xl: '2em'
	};
};

const Heading = ({children}: PropsWithChildren) => {
	const fontSize = useFontSize();
	
	return (
		<Typography variant="h3" component="h2" align="center" sx={{fontFamily: 'Galindo, sans-serif', fontSize}}>
			{children}
		</Typography>
	);
};

const Content = ({children}: PropsWithChildren) => {
	const fontSize = useFontSize();
	
	return (
		<Typography variant="h3" component="h3" align="center" sx={{fontSize}}>
			{children}
		</Typography>
	);
};

const useStyles = (width: number) => ({
	height: {
		xs: 'auto',
		sm: width
	},
	
	'> .MuiCardHeader-root, > .MuiCardContent-root': {
		position: 'relative',
		zIndex: 2
	},
	
	'&#back': {
		'> .MuiCardHeader-root': {
			opacity: .25
		},
		
		'> div': {
			'&:before': {
				display: 'none'
			}
		}
	}
});

type CardProps = {
	id: string;
	title?: ReactNode;
	subtitle?: ReactNode;
	heading: ReactNode;
	content: ReactNode;
	background?: boolean;
	width: number;
}

const Card = forwardRef<any, CardProps>(({id, title, subtitle, heading, content, background = false, width}, ref) => {
	const sx = useStyles(width);
	
	return (
		<MuiCard id={id} sx={sx} ref={ref} elevation={10}>
			<CardHeader title={title} subheader={subtitle}/>
			<CardContent>
				<Box sx={{fontSize: '2vw'}}>
					<Grid container spacing={3} justifyContent="space-evenly">
						<Grid item xs={12}><Heading>{heading}</Heading></Grid>
						<Grid item xs={12}><Content>{content}</Content></Grid>
					</Grid>
				</Box>
			</CardContent>
			{background && <CalendarBackground/>}
		</MuiCard>
	);
});

export default Card;