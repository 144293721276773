import {CssBaseline, ThemeProvider} from '@mui/material';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Calendar from './calendar';
import {useTheme} from './theme';

export default function App() {
	const theme = useTheme();
	
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			
			<BrowserRouter>
				<Routes>
					<Route path="*" element={<Calendar/>}/>
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}