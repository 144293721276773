import {Box, SxProps, Theme} from '@mui/material';
import useCalendarStyles from './useCalendarStyles';

export default function CalendarBackground() {
	const classes = useCalendarStyles();
	
	const sx: SxProps<Theme> = {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		pointerEvents: 'none',
		zIndex: 0,
		...classes.card
	};
	
	return <Box sx={sx}/>;
}