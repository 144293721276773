import {CardHeader as MuiCardHeader, CardHeaderProps, createTheme, ThemeProvider} from '@mui/material';
import useCalendarStyles from './useCalendarStyles';

export default function CardHeader(props: CardHeaderProps) {
	const classes = useCalendarStyles();
	const theme   = createTheme({palette: {mode: 'dark'}});
	
	return (
		<ThemeProvider theme={theme}>
			<MuiCardHeader {...props} sx={classes.header} titleTypographyProps={{color: 'white'}}/>
		</ThemeProvider>
	);
}