import {Grid} from '@mui/material';
import React, {useCallback, useRef, useState} from 'react';
import Favicon from 'react-favicon';
import data from '../api/data.json';
import Card from './Card';
import {days} from './constants';
import Flipper from './Flipper';

type RefAndWidth<T> = [
	(node: T) => void,
	number
]

const useRefAndWidth = (): RefAndWidth<HTMLElement> => {
	const ref               = useRef<HTMLElement>(null);
	const [state, setState] = useState({
		width: 0,
		initialized: false
	});
	
	const setRef = useCallback((node: HTMLElement) => {
		if (node) {
			const handleResize = () => {
				if (state.width !== node.offsetWidth) {
					setState((cur) => ({...cur, width: node.offsetWidth}));
				}
			};
			
			if (ref.current) {
				handleResize();
			}
			
			if (!state.initialized) {
				setState((cur) => ({...cur, initialized: true}));
				window.addEventListener('resize', handleResize);
			}
			
			setTimeout(handleResize, 100);
		}
		
		// Save a reference to the node
		// @ts-ignore
		ref.current = node;
	}, [state, setState]);
	
	return [setRef, state.width];
};

export default function Calendar() {
	const date = new Date();
	const
		day    = date.getDate(),
		month  = date.getMonth() + 1,
		year   = date.getFullYear()
	;
	
	const [frontRef, width] = useRefAndWidth();
	const description       = data[month - 1][day - 1].quote;
	const who               = data[month - 1][day - 1].who;
	
	return (
		<>
			<Favicon url="/calendar.svg"/>
			<Grid container justifyContent="center" sx={{
				pt: {
					xs: 0,
					md: 4
				}
			}}>
				<Grid item xs={12} sm={10} md={8} lg={6}>
					<Flipper>
						<Card
							id="front"
							ref={frontRef}
							title={days[date.getDay()]}
							subtitle={<>{month}/{day}/{year}</>}
							heading={<>You might be<br/>a Harris if...</>}
							content={description}
							background
							width={width}
						/>
						
						<Card
							id="back"
							heading="Who was it?"
							content={who}
							width={width}
						/>
					</Flipper>
				</Grid>
			</Grid>
		</>
	);
}