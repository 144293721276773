import {SxProps, Theme} from '@mui/material';
import {deepOrange, green, lightBlue, red} from '@mui/material/colors';
import {useMemo} from 'react';
import {fall, grass, snow, sunset} from '../img';

type CalendarStyles = {
	header?: SxProps<Theme>,
	card?: SxProps<Theme>
}

export default function useCalendarStyles() {
	const month = (new Date()).getMonth();
	
	return useMemo<CalendarStyles>(() => {
		switch (month + 1) {
			case 12:
			case 1:
			case 2:
				return {
					header: {background: lightBlue[700]},
					card: {
						background: `transparent url(${snow}) center center no-repeat`
					}
				};
			
			case 3:
			case 4:
			case 5:
				return {
					header: {background: green[700]},
					card: {
						background: `transparent url(${grass}) bottom center no-repeat`,
						opacity: .5,
						backgroundSize: 'contain'
					}
				};
			
			case 6:
			case 7:
			case 8:
				return {
					header: {background: red[700]},
					card: {
						background: `transparent url(${sunset}) bottom center no-repeat`,
						opacity: .14,
						backgroundSize: 'cover'
					}
				};
			
			case 9:
			case 10:
			case 11:
				return {
					header: {background: deepOrange[700]},
					card: {
						background: `transparent url(${fall}) bottom center no-repeat`,
						opacity: .14,
						backgroundSize: 'cover'
					}
				};
			
			default:
				return {};
		}
	}, [month]);
};